<template>
  <BaseContainer>
    <PAYGPlanSettings />
  </BaseContainer>
</template>

<script>
import PAYGPlanSettings from '@components/Settings/PAYGPlanSettings'

export default {
  page: {
    title: 'Plan'
  },

  components: {
    PAYGPlanSettings
  }
}
</script>
