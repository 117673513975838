<template>
  <div>
    <SettingsNavigation />

    <BaseWrapper>
      <PAYGPlansCard />
    </BaseWrapper>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import PAYGPlansCard from '@components/Plans/PAYGPlansCard'

export default {
  components: {
    SettingsNavigation,
    PAYGPlansCard
  }
}
</script>
