<template>
  <div v-if="paygSubscription">
    <BaseCard>
      <header class="mb-8 md:flex md:items-center md:justify-between">
        <div class="flex">
          <div class="flex-shrink-0 mr-4">
            <span
              class="flex flex-col items-center justify-center w-10 h-10 text-white rounded-full bg-secondary"
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 24 24"
              >
                <Receipt />
              </Icon>
            </span>
          </div>
          <div>
            <h2 class="text-lg">
              Plans
            </h2>
            <p class="mt-1 text-gray-600">
              All plans include our full suite of tests.
            </p>
          </div>
        </div>
      </header>

      <div class="grid w-full gap-6 mb-4 md:grid-cols-2">
        <div class="px-6 py-4 border rounded-lg">
          <p class="mb-4 text-lg">
            Your current plan
          </p>
          <div>
            <p class="pb-2">
              <span class="px-2 py-1 text-sm rounded bg-primary-200 hover:bg-gray-primary-400">
                {{ paygSubscription.plan.name }}
              </span>
            </p>
            <div>
              <p class="mt-3 text-3xl font-bold leading-none">
                {{ formatted(paygSubscription.fixed.unit_amount, paygSubscription.fixed.currency) }} <span class="text-lg font-normal text-gray-600">/year</span><span class="text-xs font-normal text-gray-600"> + tax</span>
              </p>
              <p
                v-if="paygSubscription.fixed.coupon"
                class="mt-3 text-sm leading-none text-gray-600"
              >
                Discount:
                <span v-if="paygSubscription.fixed.coupon.amount_off">
                  {{ formatted(paygSubscription.fixed.coupon.amount_off, paygSubscription.fixed.currency) }} off
                </span>
                <span v-else-if="paygSubscription.fixed.coupon.percent_off">
                  {{ paygSubscription.fixed.coupon.percent_off }}% off
                </span>
                <span v-if="paygSubscription.fixed.coupon.duration == 'forever'">
                  every year
                </span>
                <span v-else-if="paygSubscription.fixed.coupon.duration == 'once'">
                  this year
                </span>
              </p>
            </div>
            <div class="mt-6 space-y-4">
              <div class="px-4 py-3 border border-dashed rounded">
                <p class="mt-2 font-medium">
                  <TooltipButton>
                    <template v-slot:content>
                      Candidate credits <span class="py-0.5 ml-2 inline-flex px-1 text-xs text-primary rounded bg-gray-300 justify-center items-center">
                        {{ numberFormat(paygSubscription.plan.candidate_credits) }}</span>
                    </template>
                    <template v-slot:tooltip>
                      A candidate is someone who takes an assessment. Regardless of how many tests are in an assessment, each candidate uses one credit. This plan includes {{ numberFormat(paygSubscription.plan.candidate_credits) }} credits.
                    </template>
                  </TooltipButton>
                </p>
                <p class="text-gray-600">
                  {{ formatted(paygSubscription.candidates.unit_amount, paygSubscription.candidates.currency) }} each thereafter
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="paygSubscription.fixed.cancel_at_period_end"
          class="px-6 py-4 border rounded-lg"
        >
          <p class="mb-4 text-lg font-bold">
            Cancelling
          </p>
          <p class="my-4">
            You’ve cancelled your plan and your subscription will end on:
          </p>

          <p>
            <strong class="text-xl">{{ formatDate(fromUnixTime(paygSubscription.fixed.current_period_end)) }}</strong>
          </p>
          <p class="my-4">
            If you’d like to continue a plan or choose a different plan, contact our support team.
          </p>
        </div>
        <div
          v-else-if="!paygSubscription.fixed.cancel_at_period_end"
          class="px-6 py-4 border rounded-lg"
        >
          <p class="mb-4 text-lg">
            Your options
          </p>
          <div class="space-y-6">
            <div class="px-4 py-3 border border-dashed rounded">
              <div class="flex items-center justify-between">
                <p class="mr-6 text-sm">
                  Upgrade or downgrade your plan here.
                </p>
                <BaseButton
                  size="small"
                  class="justify-center flex-shrink-0 w-48"
                  :to="{ name: 'settings-payg-change-plan' }"
                >
                  Change plan
                </BaseButton>
              </div>
            </div>

            <div class="px-4 py-3 border border-dashed rounded">
              <div class="flex items-center justify-between">
                <p class="mr-6 text-sm">
                  View your current usage here.
                </p>
                <BaseButton
                  class="justify-center flex-shrink-0 w-48"
                  variant="salmon"
                  size="small"
                  :to="{ name: 'settings-payg-usage' }"
                >
                  View usage
                </BaseButton>
              </div>
            </div>

            <div class="px-4 py-3 border border-dashed rounded">
              <div class="flex items-center justify-between">
                <p class="mr-6 text-sm">
                  You can cancel your plan today and it won’t be renewed at the end of the period you’ve paid up until.
                </p>
                <BaseButton
                  variant="salmon"
                  size="small"
                  class="justify-center flex-shrink-0 w-48"
                  @click="showCancelModal = true"
                >
                  Cancel subscription
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
    <Modal
      :open="showCancelModal"
      :can-close="!cancelInProgress"
      @close="showCancelModal = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Cancel subscription
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="showCancelModal = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div
        v-if="!cancelInProgress"
        class="p-6"
      >
        <p class="mb-3">
          We’re sorry you’re thinking of cancelling your subscription. You can also choose a different plan from the Usage page instead.
        </p>
        <p class="my-3">
          If you cancel your plan today, you can continue to use your subscription unitl <strong>{{ formatDate(fromUnixTime(paygSubscription.fixed.current_period_end)) }}</strong>, and you won’t be charged after that date.
        </p>
        <p class="my-3">
          Between now and {{ formatDate(fromUnixTime(paygSubscription.fixed.current_period_end)) }} you’ll still be charged as normal for any supplementary usage during that time, as you’ve been doing so far.
        </p>
        <p class="mt-3 mb-6">
          Are you sure you’d like to cancel your subscription?
        </p>
        <p class="inline-flex space-x-2">
          <BaseButton
            @click="cancelSubscription()"
          >
            Yes, Cancel Subscription
          </BaseButton>
          <BaseButton
            variant="salmon"
            @click="showCancelModal = false"
          >
            No, Continue Subscription
          </BaseButton>
        </p>
        <p
          v-if="cancelErrorMessage"
          class="my-3"
        >
          {{ cancelErrorMessage }}
        </p>
      </div>
      <div
        v-else-if="cancelInProgress"
        class="p-6 text-center"
      >
        <Loader />
        <p class="my-3 text-sm">
          Updating your subscription
        </p>
      </div>
    </Modal>
  </div>
  <div v-else-if="!paygSubscription">
    <BaseCard
      v-if="!paygUpgradeBeforeDate"
      class="mb-4 text-center"
    >
      <p v-if="inTrial && trialDaysRemaining === 0">
        Your trial ends today
      </p>
      <p v-else-if="inTrial && trialDaysRemaining === 1">
        You have one day left of your trial
      </p>
      <p v-else-if="inTrial && trialDaysRemaining > 1">
        You have {{ trialDaysRemaining }} days left of your trial
      </p>
      <p v-else-if="!inTrial">
        Your trial has expired
      </p>
    </BaseCard>
    <BaseCard
      v-else-if="paygUpgradeBeforeDate"
      class="mb-4 text-center"
    >
      <p>
        We’ve recently updated our plans. You can use Test Candidates as you did before until {{ paygUpgradeBeforeDate }}.<br>
        After then, you can subscribe to one of our new plans to continue using Test Candidates.
      </p>
    </BaseCard>
    <BaseCard>
      <header class="mb-8 md:flex md:items-center md:justify-between">
        <div class="flex">
          <div class="flex-shrink-0 mr-4">
            <span
              class="flex flex-col items-center justify-center w-10 h-10 text-white rounded-full bg-secondary"
            >
              <Icon
                width="24px"
                height="24px"
                view-box="0 0 24 24"
              >
                <Receipt />
              </Icon>
            </span>
          </div>
          <div>
            <h2 class="text-lg">
              Plans
            </h2>
            <p class="mt-1 text-gray-600">
              All plans include our full suite of tests.
            </p>
          </div>
        </div>

        <div class="flex items-center justify-end">
          <span class="relative z-0 inline-flex rounded-md shadow-sm">
            <span
              v-for="(currency, index) in currencies"
              :key="index"
              :class="[
                'relative inline-flex items-center px-4 py-2 text-sm font-medium border border-gray-300 cursor-pointer duration-150 ease-out',
                selectedCurrency === currency
                  ? 'bg-secondary border-secondary text-white'
                  : 'bg-white text-gray-800 hover:bg-gray-50',
                index === 0 ? 'rounded-l' : 'rounded-none',
                index === currencies.length - 1 ? 'rounded-r' : 'rounded-none',
                index !== 0 ? '-ml-px ' : '-ml-0'
              ]"
              @click="selectedCurrency = currency"
            >
              {{ currency }}
            </span>
          </span>
        </div>
      </header>

      <div v-if="state === states.READY">
        <div class="grid w-full gap-3 mb-4 md:grid-cols-3">
          <div
            v-for="plan in currencyPlans"
            :key="plan.level"
            class="flex flex-col px-6 py-4 border rounded-lg"
          >
            <p class="pb-2">
              <span class="px-2 py-1 text-sm rounded bg-primary-200 hover:bg-gray-primary-400">
                {{ plan.name }}
              </span>
            </p>
            <div>
              <p class="mt-3 text-3xl font-bold leading-none">
                {{ formatted(plan.products.fixed.price.unit_amount, selectedCurrency) }} <span class="text-lg font-normal text-gray-600">/year</span><span class="text-xs font-normal text-gray-600"> + tax</span>
              </p>
            </div>
            <div class="mt-6 space-y-4">
              <div class="px-4 py-3 border border-dashed rounded">
                <p class="flex items-center font-medium">
                  <TooltipButton>
                    <template v-slot:content>
                      Candidate credits <span class="py-0.5 ml-2 inline-flex px-1 text-xs text-primary rounded bg-gray-300 justify-center items-center">
                        {{ numberFormat(plan.candidate_credits) }}</span>
                    </template>
                    <template v-slot:tooltip>
                      A candidate is someone who takes an assessment. Regardless of how many tests are in an assessment, each candidate uses one credit. This plan includes {{ numberFormat(plan.candidate_credits) }} credits.
                    </template>
                  </TooltipButton>
                </p>
                <p class="text-gray-600">
                  {{ formatted(plan.products.candidates.price.unit_amount, selectedCurrency) }} each thereafter
                </p>
              </div>
            </div>

            <BaseButton
              variant="green"
              class="justify-center w-full mt-6"
              :to="{ name: 'settings-checkout', params: { currency: selectedCurrency.toLowerCase(), plan: plan.slug }}"
            >
              Start Subscription
            </BaseButton>
          </div>
        </div>
      </div>

      <div
        v-else-if="state === states.LOADING"
        class="my-8"
      >
        <Loader />
      </div>

      <div
        v-else-if="state === states.ERROR"
        class="my-8"
      >
        Sorry, an error occurred
      </div>
    </BaseCard>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Receipt from '@components/Icons/Receipt'
import Plus from '@components/Icons/Plus'
import Modal from '@components/Modal'
import TooltipButton from '@components/TooltipButton'

import { formatCurrencyWhole } from '@utils/currency'
import { formatDate } from '@utils/formatDate'
import { mapGetters } from 'vuex'
import { numberFormat } from '@utils/numberFormat'
import { fromUnixTime, differenceInDays } from 'date-fns'
import pluralize from 'pluralize'

import plansApi from '@api/plans'
import states from '@api/states'

export default {
  components: {
    Icon,
    Loader,
    Receipt,
    Plus,
    Modal,
    TooltipButton
  },

  data() {
    return {
      numberFormat,
      formatDate,
      fromUnixTime,
      pluralize,

      states,
      error: null,

      // Cancelling variables
      showCancelModal: false,
      cancelInProgress: false,
      cancelErrorMessage: null,

      plans: null,
      selectedCurrency: null,

      currencies: [
        'GBP', 'EUR', 'USD', 'AUD'
      ]
    }
  },

  computed: {
    ...mapGetters({
      paygSubscription: 'subscriptions/paygSubscription',
      trialEndedAt: 'organisations/trialEndedAt',
      paygUpgradeBeforeDate: 'organisations/paygUpgradeBeforeDate',
      inTrial: 'organisations/inTrial'
    }),

    /**
     * @return {Number}
     */
    trialDaysRemaining() {
      return differenceInDays(new Date(this.trialEndedAt), new Date())
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.plans || !this.selectedCurrency) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * Returns the plans but only includes prices for the currency selected
     *
     * @return {Array}
     */
    currencyPlans() {
      return this.plans.map(plan => {
        plan.products.fixed.price = plan.products.fixed.prices.find(price => price.currency === this.selectedCurrency)
        plan.products.candidates.price = plan.products.candidates.prices.find(price => price.currency === this.selectedCurrency)

        return plan
      })
    }
  },

  mounted() {
    this.loadPlans()
  },

  methods: {
    /**
     * Load in products if missing
     */
    loadPlans() {
      plansApi.paygIndex()
        .then(response => {
          this.selectedCurrency = response.data.currencySuggestion
          this.plans = response.data.plans
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * @param {object} currencies
     * @return {string}
     */
    formatted(amount, currency) {
      if (!currency) {
        return amount / 100
      }
      return formatCurrencyWhole(amount / 100, currency)
    },

    /**
     * Start process for cancelling a subscription
     */
    cancelSubscription() {
      if (this.cancelInProgress) {
        return
      }

      this.cancelInProgress = true
      this.cancelErrorMessage = null

      this.$store.dispatch('subscriptions/cancelPaygSubscription')
        .then(() => {
          this.$store.dispatch('subscriptions/getSubscriptions')
            .then(() => {
              this.cancelInProgress = false
              this.showCancelModal = false
            })
        })
        .catch(error => {
          this.cancelInProgress = false
          this.cancelErrorMessage = 'Sorry, we couldn’t process cancelling your subscription right now.'
          throw error
        })
    }
  }
}
</script>
